import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import AGREESeriesImage from "../../components/logicom-images/AGREESeries.js"

const AgreeTest = () => (
    <Layout>
      <SEO title="AGREE Test System" />
      <Container>
        <Row><Col><h2>AGREE Test System</h2></Col></Row>
        <Row className={'row-cols-1 row-cols-md-2'}>
          <Col>
            <div>
              <p>Thermotron combined environment test chambers, also known as AGREE Chambers, provide the flexibility to adapt to your changing test requirements. The chambers, available in temperature or temperature/humidity configurations, can be integrated with a Thermotron electrodynamic or repetitive shock shaker. The versatility of the chambers can be further enhanced with the addition of double doors, vertical lift or sliding doors, adjustable height, or floor plug cart assemblies. Standard temperature transition rates of 5°C per minute up to 30°C per minute are available. Faster change rates can be AGREEachieved using LN2 or CO2 boost or multiple refrigeration machine sections. A variable humidity range of 20% to 95% RH is available on some models. Thermotron combined environment test chambers incorporate proven performance and versatility to meet or exceed your testing needs.</p></div>
        </Col>
         <Col>
            <AGREESeriesImage/>
          </Col>
        </Row>
        <Row><Col>
              <div><p>At Thermotron we design, manufacture and test all of our AGREE system components including the chamber, vibration system, controller, fixtures and the complete interface. We offer AGREE chambers in sizes ranging from 12 to 168 cubic feet (340 to 4,757 liters). We can also customize them in many ways. Let us put our design experience and customization capabilities to work to meet your individual testing needs.</p>
              <p>Originally designed for the defense and aerospace industry applications to perform a specific series of temperature, humidity and vibration tests conforming with MIL-STD 781 and 883, AGREE chambers have been adapted to perform reliability, qualification, and Environmental Stress Screening (ESS) tests for the electronics, automotive and telecommunications industries.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
  
  export default AgreeTest
